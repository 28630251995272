import React, { useState, Fragment } from 'react';
import { navigate } from 'gatsby';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { withFirebase } from '../../../utils/Firebase';

import SignInForm from '../../molecules/SignInForm/sign-in-form';

import PasswordForgetLink from '../../atoms/PasswordForgetLink/password-forget-link';
import SignUpLink from '../../atoms/SignUpLink/sign-up-link';

import Logo from '../../../assets/adsight.png';
import ResponseHandler from '../../molecules/ResponseHandler/response-handler';

import { INVENTORIES } from '../../../constants/routes';

// Styles
import './sign-in.scss';

const signInSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string().required('Required'),
});

const SignIn = ({ firebase }) => {
  const incomQuery =
    typeof window !== 'undefined'
      ? new URLSearchParams(window.location.search)
      : undefined;

  const redirectURL = incomQuery && incomQuery.get('redirectURL');

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email: '', password: '' },
    validationSchema: signInSchema,
    onSubmit: async (values) => {
      setSubmitLoading(true);
      try {
        await firebase.doSignInWithEmailAndPassword(
          values.email,
          values.password,
        );
        const homePath = getRedirectPath();
        return navigate(homePath);
      } catch (signInError) {
        setSubmitLoading(false);
        setSubmitError(signInError);
      }
    },
  });

  const [response, setResponse] = useState(() => {
    const param = incomQuery ? incomQuery.get('message') : undefined;
    if (param) {
      return { type: 'info', title: "You're not logged in", message: param };
    } else return undefined;
  });

  const getRedirectPath = () => {
    if (redirectURL) return redirectURL;
    const supplier =
      typeof window !== 'undefined' && localStorage.getItem('supplier');
    const homePath = INVENTORIES + (supplier ? `/${supplier}` : '');
    return homePath;
  };

  const deleteMessage = () => setResponse(undefined);

  return (
    <Fragment>
      {response && (
        <ResponseHandler response={response} deleteMessage={deleteMessage} />
      )}
      <div>
        <div className="gradient-bar" />
        <div className="login">
          <div className="login__content">
            <div className="login__content__inner">
              <div className="login__content__header">
                <img
                  className="login__content__header__logo"
                  src={Logo}
                  alt="logo"
                />
              </div>
              <div className="login__content__login-form">
                <div className="login__content__form">
                  <SignInForm
                    values={formik.values}
                    dirty={formik.dirty}
                    touched={formik.touched}
                    errors={formik.errors}
                    isValid={formik.isValid}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    onSubmit={formik.handleSubmit}
                    submitLoading={submitLoading}
                    submitError={submitError}
                  />
                </div>
                <div className="login__content__password-forget">
                  <PasswordForgetLink />
                </div>
                <div className="login__content__create-account">
                  <SignUpLink isRegister redirectURL={redirectURL} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withFirebase(SignIn);
