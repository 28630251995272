import React from 'react';
import { SIGN_IN, SIGN_UP } from '../../../constants/routes';
import { Link } from 'gatsby';

const SignUpLink = ({ isRegister, redirectURL }) => (
  <p>
    {isRegister ? (
      <>
        Don't have an account?{' '}
        <Link
          to={redirectURL ? `${SIGN_UP}/?redirectURL=${redirectURL}` : SIGN_UP}
        >
          Sign Up.
        </Link>
      </>
    ) : (
      <>
        <br />
        Already have an account?{' '}
        <Link
          to={redirectURL ? `${SIGN_IN}/?redirectURL=${redirectURL}` : SIGN_IN}
        >
          Sign In.
        </Link>
      </>
    )}
  </p>
);

export default SignUpLink;
