import React from 'react';
import Layout from '../utils/layout';
import SignIn from '../components/scenes/SignIn/sign-in';

const SignInPage = () => {
  return (
    <Layout>
      <SignIn />
    </Layout>
  );
};

export default SignInPage;
