import React from 'react';

import Input from '../../atoms/Input/input';
import Button from '../../atoms/Button/button';

const SignInForm = ({
  values,
  dirty,
  touched,
  errors,
  isValid,
  handleChange,
  handleBlur,
  onSubmit,
  submitLoading,
  submitError,
}) => {
  return (
    <div>
      <form onSubmit={onSubmit}>
        <Input
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          labelName="Email"
          required
        />
        {errors.email && touched.email && (
          <p className="error-message">{errors.email}</p>
        )}
        <Input
          name="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          type="password"
          labelName="Password"
          required
        />
        {errors.password && touched.password && (
          <p className="error-message">{errors.password}</p>
        )}
        <br />
        <Button
          className={
            submitLoading ? 'button is-primary is-loading' : 'button is-primary'
          }
          disabled={!isValid || !dirty}
          type="submit"
          text="Log in"
        />
        <br />

        {submitError && <p>{submitError.message}</p>}
      </form>
    </div>
  );
};

export default SignInForm;
